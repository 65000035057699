

.padding {
padding-left: 10%;
padding-right: 10%;
padding-top: 2%;
padding-bottom: 8%;
min-height: 800px;
color: #3a3831;
margin-bottom: 100px;
}

li{
    margin: 20px 0;
  }