/* .inherit {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
*/

.Software-Item {
    /* border: 2px solid #1098F7; */
    color: #3a3831;
    /* padding: 0 25px 0 25px;*/
    margin: 25px 10% 10px 10%;
    background-color: white;
    /* width: 800px;*/
    box-shadow: 5px 10px 8px #888888;
  }

  .Box-Padding {
    padding: 10px 40px 10px 40px;
    /* margin: 5px 5px 5px 5px; */
  }

  .Item-Header {
    text-align: left;
   padding: 25px 0px 0px 50px;
    color: #3a3831;
    /* color: #1D4E89; */ 
    font-size: 36px;
    font-weight: 450;

  }

  xbutton {
    padding: 15px;
    margin: 50px; 
    width: 150px;
    font-size: 24px;
    border-radius: 50px;
    border: 1px solid #F0F0F0;
    background-color: #F0F0F0;
    color: #1098F7;
    font-weight: bold;
    
  }

  xbutton:hover {

    background-color: white;
      color: #1D4E89;
      border: 1px solid #1098F7;

  }

  .buttons {
    align-items: center;
    display: flex;
    justify-content: center;
  }

 

  @media only screen and (max-width: 600px) {
    .Software-Item {
      border: 1px solid white; 
      color: #1D4E89;
      /* padding: 0 10px 0 10px; */
      margin: 5px 0px 4px 0px;
      background-color: white;
      /* width: 800px;*/
      box-shadow: none;
  
  
    }
    
    xbutton {
      padding: 10px;
      margin: 10px; 
      width: 100px;
      font-size: 16px;
      border: 1px solid #F0F0F0;
      background-color: #F0F0F0;
      color: #1098F7;
      
    }

    xbutton:hover {

      background-color: white;
      color: #1D4E89;
      border: 1px solid #1098F7;
  
    }
  
    .buttons {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .Box-Padding {
      padding: 0px 10px 10px 10px;
      /* margin: 5px 5px 5px 5px; */
    }
  }
