/*
.header {
  color: #FBD1A2;
  font-size: 36px;
  text-align: left;
  padding: 15px;
  font-family: "Papyrus", cursive;

  
}

.nav {
  background-color: #F79256;
  width: 100vw;
  position: relative;
}

*/

.footer {
  
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f2e4d3;
    text-align: left;
 
  color: #FBD1A2;
  font-size: 18px;
padding: 25px !important;
  font-family: "Papyrus", cursive;

}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;

  
}
a:visited { text-decoration: none; color:#3a3831; }
a:hover { text-decoration: none; color:#3a3831; }
a:focus { text-decoration: none; color:#3a3831; }
a:hover, a:active { text-decoration: none; color:#3a3831 }



/* Create the ancient building container */
.nav {
  padding: 20px;
  /* border: 6px solid #F5DEB3;  Roman red */
  margin: 0 auto;
  font-family: 'Times New Roman', serif;
  font-size: 36px;
  font-family: "Papyrus", cursive;
  text-shadow: 2px 2px 2px  white, 4px 0 0  #f2e4d3;
  color: #3a3831;
  position: relative;
  
  width: 100%;
  
}




