

.App {
  background-color: whitesmoke;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);

  /* align-items: center; */
 
}



@media only screen and (max-width: 600px) {
.App {
  background-color: whitesmoke;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  /* align-items: center; */
 
}

}

