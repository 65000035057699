button {
    background-color: #523BE4;
    color: white;
    border: 1px solid #523BE4;
    border-radius: 50px;
    width: 150px;
    padding: 15px;
    font-size: 24px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
}

button:hover {
    background-color: #523BE4;
    color: white;
opacity: 50%;
border: 1px solid #523BE4;

  }